import React, { useState } from 'react'
import './Rate.scss'
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";

Rate.propTypes = {
  currency: PropTypes.string.isRequired,
  week: PropTypes.string.isRequired,
}

export default function Rate({ currency, week }) {
  const [open, setOpen] = useState(false);

  const StyledCloseIcon = withStyles({
    root: {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: 6,
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: 'rgba(0,0,0,0.05)',
      color: '#808080',
    },
  })(CloseIcon)

  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <aside className="Rate">
      <div className="Rate-Block">
        <div className="Rate-Currency">
            {currency}
        </div>
        <div className="Rate-Help" onClick={handleClickOpen} />
        <Dialog
          open={open}
          onClose={handleClose}
          className='Rate-Dialog'
        >
          <StyledCloseIcon
            onClick={handleClose}
          />
          <div className="Rate-Dialog-Text">
            <div className="Rate-Dialog-Currency">
              {currency}
            </div>
            Внутренний курс компании на оборудование
            <br />и принадлежности на неделю:
            <span className="Rate-Week"> {week}</span>
          </div>
        </Dialog>
        <div className="Rate-Text">
          Внутренний курс компании на оборудование
          <br />и принадлежности на неделю:
          <span className="Rate-Week"> {week}</span>
        </div>
      </div>
    </aside>
  )
}

export const THEME = {
  PRIMARY_LIGHT: 'rgba(38, 154, 140, 0.15)',
  PRIMARY: '#269A8C',
  PRIMARY_DARK: '#00897E',
  SECONDARY: '#fff',
  SECONDARY_DARK: '#eee',
  BLOCK: 'rgba(0, 0, 0, 0.3)',
  BLOCK_DARK: 'rgba(0, 0, 0, 0.4)',
  BLOCK_DARKER: 'rgba(0, 0, 0, 0.5)',
  LIGHT_BLOCK: 'rgba(255, 255, 255, 0.6)',
  LIGHT_BLOCK_DARK: '#F6F6F6',
  GREY: 'rgba(0, 0, 0, 0.54)',
  LIGHT_GREY: 'rgba(0, 0, 0, 0.05)',

  FONT_BLACK: '#000',
  FONT_GREY: 'rgba(0, 0, 0, 0.7)',
  FONT_LIGHT_GREY: 'rgba(0, 0, 0, 0.15)',
  FONT_DARK_GREY: 'rgba(0, 0, 0, 0.5)',

  FONT_PRIMARY: '#FFF',
  FONT_PRIMARY_LIFHT: 'rgba(255, 255, 255, 0.5)',
  FONT_PRIMARY_DARK: 'rgba(255, 255, 255, 0.7)',

  BORDER_LIGHT: 'rgba(255, 255, 255, 0.3)',

  SUCCESS: '#43a047',
  SUCCESS_LIGHT: '#62eb66',
  ERROR: '#d32f2f',

  FONT_FAMILY: 'Roboto',
  FS_SM: 14,
  FS_ST: 16,
  FS_MD: 20,
  FS_LG: 36,
  FS_XL: 48,

  SHORTEST: 150,
  SHORTER: 200,
  SHORT: 250,
  STANDARD: 300,
  MEDIUM: 500,
  LONG: 800,
  LONGER: 1000,
  LONGEST: 1200,
  EASE_IN_OUT: 'cubic-bezier(0.4, 0, 0.2, 1)',
  EASE_OUT: 'cubic-bezier(0, 0, 0.2, 1)',
}

const MUI_THEME = {
  typography: {
    fontFamily: THEME.FONT_FAMILY,
  },
  palette: {
    text: {
      primary: THEME.SECONDARY,
    },
    primary: {
      light: THEME.PRIMARY_LIGHT,
      main: THEME.PRIMARY,
      dark: THEME.PRIMARY_DARK,
    },
    secondary: {
      light: THEME.SECONDARY,
      main: THEME.SECONDARY,
      dark: THEME.SECONDARY_DARK,
    },
    background: {
      default: THEME.SECONDARY,
      paper: THEME.BLOCK,
    },
  },
  shape: {
    borderRadius: 0,
  },
  transitions: {
    easing: {
      easeInOut: THEME.EASE_IN_OUT,
      easeOut: THEME.EASE_OUT,
    },
    duration: {
      shorter: THEME.SHORTER,
      shortest: THEME.SHORTEST,
      short: THEME.SHORT,
      standard: THEME.STANDARD,
    },
  },
  props: {
    MuiMenu: {
      disablePortal: true,
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
    MuiMenu: {
      paper: {
        '.Request &': {
          backgroundColor: THEME.SECONDARY,
          color: '#000',
        },
      },
    },
    MuiButtonBase: {
      root: {
        font: 'inherit',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        paddingTop: 2,
      },

    },
    MuiTabs: {
      indicator: {
        display: 'none'
      },
      flexContainer: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    MuiTab: {
      root: {
        transition: `background-color ${THEME.SHORT}ms ${THEME.EASE_IN_OUT}`,
        '&.Mui-selected': {
          backgroundColor: THEME.PRIMARY,
        },
        maxWidth: '100%',
      },
      textColorInherit: {
        opacity: 1,
      },
      wrapper: {
        color: THEME.FONT_PRIMARY_LIFHT,
        fontSize: 16,
        transition: `color ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
        '.MuiTab-root.Mui-selected &': {
          color: THEME.FONT_PRIMARY,
        },
        alignItems: 'flex-start',
        textTransform: 'none'
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: THEME.FS_ST,
      },
    },
    MuiPaper: {
      root: {
        '&.ExtendedTable': {
          backgroundColor: THEME.LIGHT_BLOCK,
        },
        '&.MenuDropdown': {
          backgroundColor: THEME.SECONDARY,
          boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08) !important',
        },
        top: '6px !important',
        minWidth: '304px !important',
      },
      elevation1: {
        width: '304px',
        height: '256px',
        minWidth: '100%',
        padding: '17px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '25px',
        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.08) !important',
      }
    },
    MuiPopover: {
      paper: {
        borderRadius: '12px !important'
      }
    },
    MuiTablePagination: {
      root: {
        color: THEME.PRIMARY,
        borderBottom: 'none',
      },
      selectIcon: {
        '&.MuiTablePagination-selectIcon': {
          color: THEME.PRIMARY,
        },
      },
    },
    MuiTableCell: {
      body: {
        color: '#000',
      },
      root: {
        borderBottomColor: THEME.BLOCK,
        '&::first-letter': {
          textTransform: 'uppercase',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '.Request &': {
          color: '#000',
          boxShadow: 'none',
          border: `1px solid ${THEME.BLOCK}`,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0 16px',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingTop: 0,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiTableSortLabel: {
      active: {
        '& > svg': {
          fill: THEME.PRIMARY,
        },
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: THEME.SECONDARY,
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#000',
      },
    },
    MuiInputBase: {
      root: {
        '.Request &': {
          color: '#000',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '.Request &:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: THEME.PRIMARY,
        },
      },
    },
    MuiList: {
      root: {
        backgroundColor: THEME.SECONDARY,
      },
      padding: {
        paddingBottom: '12px'
      }
    },
    MuiListItem: {
      button: {
        color: THEME.FONT_GREY,
      },
    },
    MuiDrawer: {
      modal: {
        top: '117px !important',
      },
      paper: {
        top: '117px !important',
        width: '90%',
        height: 'calc(100% - 117px)',
        backgroundColor: THEME.SECONDARY,
        boxShadow: 'none',
      }
    },
    MuiBackdrop: {
      root: {
        top: '117px',
      }
    }
  },
}

export default MUI_THEME

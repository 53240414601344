import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ScrollAnimation from '../_common/ScrollAnimation'
import { THEME } from '../../styles/muiTheme'
import '../Links/LinksSection.scss'
import LinkItem from "./LinkItem";

export default class LinksSection extends Component {

  static propTypes = {
    block: PropTypes.object.isRequired,
  }

  render() {
    const { block } = this.props

    return (
      <>
        <section className="LinksSection">
          <h2 className="LinksSection-Title">
            <span className="LinksSection-TitleText FadeInMedium">
              {block.name}
            </span>
          </h2>
          <Grid container component="ul" spacing={3} className='LinksSection-Block'>
            {block.links.map((link, index) => (
              <Grid key={link.name} item component="li" >
                <ScrollAnimation effect="fadeInBottomSmall" duration={THEME.LONG} >
                  <LinkItem link = {link}/>
                </ScrollAnimation>
              </Grid>
            ))}
          </Grid>
        </section>
      </>
    )
  }
}

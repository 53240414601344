import React from 'react'
import LINKS from '../../utils/links'
import LinksSection from "./LinksSection";


export default function Links() {
  return (
    <>
      <h1 className='Link-Title'>Полезные ссылки</h1>
      <LinksSection block = {LINKS.vaillant}/>
      <LinksSection block = {LINKS.protherm}/>
      <LinksSection block = {LINKS.additional}/>
    </>
  )
}

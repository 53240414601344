import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import MenuDropdown from '../_common/MenuDropdown'
import {kc} from '../../index'
import './style.scss'
import { getFormattedTelephone } from "../../utils/helpers";

Header.propTypes = {
  user: PropTypes.object.isRequired,
}

function Header({user}) {
  const userLinks = [
    {
      id: 1,
      text: 'Профиль',
      href: `${kc.authServerUrl}/realms/${kc.realm}/account`,
    },
    {
      id: 2,
      text: 'Выйти из аккаунта',
      handler() {
        kc.logout(process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : `${window.location.origin}`)
      },
    },
  ]
  return (
    <AppBar className="Header" color="default" position="static" elevation={0}>
      <div className="Header-Container Container">
        <div className="Header-Content">
          <MenuDropdown
            links={userLinks}
            user={user}
          >
            <span className="Header-UserAvatar" />
            <div className="Header-UserInfo">
              <p>
                {user.firstName} {user.lastName}
              </p>
              <p className="Header-UserEmail">
                {getFormattedTelephone(user.login)}
              </p>
            </div>
          </MenuDropdown>
        </div>
      </div>
    </AppBar>
  )
}

export default React.memo(Header)

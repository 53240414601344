import React, {Component, Fragment} from 'react'
import axios from 'axios'
import Portal from '@material-ui/core/Portal'
import CircularProgress from '@material-ui/core/CircularProgress'

import Notification from '../_common/Notification'
import Main from '../Main'
import {BASE_KC_URL} from '../../index'
import {INITIAL_REQUESTS_STATE} from '../../utils/constants'
import './style.scss'

export default class AppContent extends Component {
  state = {
    user: null,
    isUserFetch: false,

    systems: null,
    isSystemsFetch: false,

    isRegistration: false,

    requests: null,
    isRequestsFetch: false,

    statuses: null,
    isStatusesFetch: false,

    isSuccessSnackbarOpen: false,
    isErrorSnackbarOpen: false,
    snackbarMessage: '',

    isRateFetch: false,
    rate: [],
  }

  componentDidMount() {
    axios
      .get(`${BASE_KC_URL}/uls-request/user`)
      .then(({data}) => this.setState({user: data}))
      .catch((error) => this.handleErrorSnackbarOpen(error))
  }

  handleRegistration = (id, closeDialog) => {
    this.setState({isRegistration: true})
    axios
      .post('/Applications/roleRequest', {
        applicationId: id,
      })
      .then(() => {
        this.setState({isRegistration: false})
        closeDialog()
        this.handleSuccessSnackbarOpen('Заявка отправлена.')
      })
      .catch((error) => {
        this.setState({isRegistration: false})
        this.handleErrorSnackbarOpen(error)
      })
  }

  handleSystemsFetch = () => {
    this.setState({isSystemsFetch: true})
    axios
      .get('/Applications')
      .then(({data}) => {
        this.setState({
          systems: data,
          isSystemsFetch: false,
        })
      })
      .catch((error) => {
        this.setState({
          isSystemsFetch: false,
        })
        this.handleErrorSnackbarOpen(error)
      })
  }

  handleRequestsFetch = (
    size = INITIAL_REQUESTS_STATE.size,
    page = INITIAL_REQUESTS_STATE.page,
    sort = INITIAL_REQUESTS_STATE.sort,
    search = INITIAL_REQUESTS_STATE.search
  ) => {
    this.setState({isRequestsFetch: true})
    const params = {
      size,
      page,
    }
    if (sort) {
      params.criteria = sort[0]
      params.order = sort[1]
    }
    if (search) {
      params.search = search
    }
    axios
      .get(`${BASE_KC_URL}/uls-request/request-journal`, {params})
      .then(({data}) => this.setState({requests: data, isRequestsFetch: false}))
      .catch((error) => {
        this.setState({isRequestsFetch: false})
        this.handleErrorSnackbarOpen(error)
      })
  }

  handleStatusesFetch = () => {
    this.setState({isStatusesFetch: true})
    axios
      .get(`${BASE_KC_URL}/uls-request/request-status`)
      .then(({data}) => this.setState({statuses: data, isStatusesFetch: false}))
      .catch((error) => {
        this.setState({isStatusesFetch: false})
        this.handleErrorSnackbarOpen(error)
      })
  }

  handleSuccessSnackbarOpen = (snackbarMessage) =>
    this.setState({snackbarMessage, isSuccessSnackbarOpen: true, isErrorSnackbarOpen: false})

  handleErrorSnackbarOpen = (error) => {
    const isErrorResponse = error && error.response
    this.setState({
      snackbarMessage: isErrorResponse
        ? error.response.data.message || error.response.data.title || error.response.statusText
        : 'Ошибка соединения с сервером',
      isErrorSnackbarOpen: true,
      isSuccessSnackbarOpen: false,
    })
  }

  handleSnackbarClose = () => this.setState({isSuccessSnackbarOpen: false, isErrorSnackbarOpen: false})

  handleRateFetch = () => {
    const apiKey = 'AIzaSyAYfjOjL6Z6OHv-csA6TH_4XWIPlkIDN7E';
    const spreadsheetId = '1FBefYm-108H2xiodCzfwKLjHeWOvKJmpDAE9tG2cQHo';
    const sheetName = 'Лист1';

    axios
      .get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}?key=${apiKey}&range=A1:A2`)
      .then(({data}) => {
        this.setState({rate: data.values})
        this.setState({isRateFetch: true})
      })
      .catch((error) => {
        this.setState({
          isRateFetch: false,
        })
        this.handleErrorSnackbarOpen(error)
      })
  }

  render() {
    const {
      user,
      systems,
      isSystemsFetch,
      isRegistration,
      requests,
      isRequestsFetch,
      statuses,
      isStatusesFetch,
      isSuccessSnackbarOpen,
      isErrorSnackbarOpen,
      snackbarMessage,
      isRateFetch,
      rate
    } = this.state
    return (
      <Fragment>
        <div className="AppContent">
          {user ? (
            <div className="AppContent-Wrapper FadeInMedium">
              <Main
                user={user}
                isAdmin={user.localAdmin}
                systems={systems}
                isSystemsFetch={isSystemsFetch}
                isRegistration={isRegistration}
                requests={requests}
                isRequestsFetch={isRequestsFetch}
                statuses={statuses}
                isStatusesFetch={isStatusesFetch}
                onSystemsFetch={this.handleSystemsFetch}
                onRegistration={this.handleRegistration}
                onRequestsFetch={this.handleRequestsFetch}
                onStatusesFetch={this.handleStatusesFetch}
                onErrorSnackbarOpen={this.handleErrorSnackbarOpen}
                onRateFetch={this.handleRateFetch}
                isRateFetch={isRateFetch}
                rate={rate}
              />
            </div>
          ) : (
            <div className="AppContent-Loading">
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
        <Portal container={document.body}>
          <Notification
            open={isSuccessSnackbarOpen}
            variant="success"
            message={snackbarMessage}
            onClose={this.handleSnackbarClose}
          />
          <Notification
            open={isErrorSnackbarOpen}
            variant="error"
            message={snackbarMessage}
            onClose={this.handleSnackbarClose}
          />
        </Portal>
      </Fragment>
    )
  }
}

import React from 'react'

import './style.scss'

function Footer() {
  return (
    <footer className="Footer">
      <p className="Footer-Terms">
        <a
          className="Footer-Link"
          href="https://vgr.direct/static/soglasie.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Согласие на обработку персональных данных
        </a>
        <br />
        <a
          className="Footer-Link"
          href="https://vgr.direct/static/polozhenie.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Положение об обработке персональных данных
        </a>
      </p>
    </footer>
  )
}

export default React.memo(Footer)

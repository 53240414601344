import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter, Switch, Route, Link, Redirect} from 'react-router-dom'
import MuiTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Systems from '../Systems'
import Requests from '../Requests'
import ROUTES from '../../utils/routes'
import './style.scss'
import Header from "../Header";
import HeaderMobile from "../Header/HeaderMobile";
import Footer from "../Footer";
import Rate from "../Header/Rate";
import { Box, Drawer } from "@material-ui/core";
import Links from "../Links";

const scrollTop = () => window.scrollTo(0, 0)

Main.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  systems: PropTypes.object,
  isSystemsFetch: PropTypes.bool.isRequired,
  isRegistration: PropTypes.bool.isRequired,
  requests: PropTypes.object,
  isRequestsFetch: PropTypes.bool.isRequired,
  statuses: PropTypes.array,
  isStatusesFetch: PropTypes.bool.isRequired,

  onSystemsFetch: PropTypes.func.isRequired,
  onRegistration: PropTypes.func.isRequired,
  onRequestsFetch: PropTypes.func.isRequired,
  onStatusesFetch: PropTypes.func.isRequired,
  onErrorSnackbarOpen: PropTypes.func.isRequired,
  onRateFetch: PropTypes.func.isRequired,
  isRateFetch: PropTypes.bool.isRequired,
  rate: PropTypes.array.isRequired
}

function Main({
  user,
  location,
  isAdmin,
  systems,
  isSystemsFetch,
  isRegistration,
  requests,
  isRequestsFetch,
  statuses,
  isStatusesFetch,
  onSystemsFetch,
  onRegistration,
  onRequestsFetch,
  onStatusesFetch,
  onErrorSnackbarOpen,
  onRateFetch,
  isRateFetch,
  rate
}) {

  const [show, setShow] = useState(false);
  const tabsValue = location.pathname === ROUTES.SYSTEMS ? 0 : 1;
  const currentYear = new Date().getFullYear();
  const isMobile = window.innerWidth < 768;
  const currency = isRateFetch ? rate[1][0] : ''
  const week = isRateFetch ? rate[0][0].split(" ")[1] + ' - ' + rate[0][0].split(" ")[3] : '';

  useEffect(() => {
    onRateFetch()
  }, [onRateFetch])


  const renderTabs = () => {
    return (
      <nav className="Main-Navigation FadeInRightLongest">
        <MuiTabs value={tabsValue} color="secondary" indicatorColor="secondary">
          <Tab to={ROUTES.SYSTEMS} label="Системы" component={Link} />
          <Tab to={ROUTES.LINKS} label="Полезные ссылки" component={Link} />
        </MuiTabs>
      </nav>
    )
  }

  const renderRoutes = () => {
    return (
      <Switch>
        <Route
          exact
          path={ROUTES.SYSTEMS}
          render={(props) => (
            <Systems
              {...props}
              systems={systems}
              isSystemsFetch={isSystemsFetch}
              isRegistration={isRegistration}
              onSystemsFetch={onSystemsFetch}
              onRegistration={onRegistration}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.LINKS}
          render={() => (
            <Links />
          )}
        />
        {isAdmin && (
          <Route
            exact
            path={ROUTES.REQUESTS}
            render={(props) => (
              <Requests
                {...props}
                requests={requests}
                isRequestsFetch={isRequestsFetch}
                statuses={statuses}
                isStatusesFetch={isStatusesFetch}
                onRequestsFetch={onRequestsFetch}
                onStatusesFetch={onStatusesFetch}
                onErrorSnackbarOpen={onErrorSnackbarOpen}
              />
            )}
          />
        )}
        <Redirect to={ROUTES.SYSTEMS} />
      </Switch>
    )
  }

  const renderFooterRules = () => {
    return (
      <p  className="Main-Section-Footer">ООО "ВГР" {currentYear}. Все права защищены.</p>
    )
  }

  return (
    <main className={'Main ' + (isMobile && 'Main-Mobile')}>
      {!isMobile ? (
        <>
          <div className="Main-Sidebar">
            <div className="Main-Sidebar-Content">
              <button className="Header-Logo" onClick={scrollTop}>
                <span className='Header-VGRussia' />
              </button>
              <Rate
                currency={currency}
                week={week}
              />
              {renderTabs()}
            </div>
            <Footer />
          </div>
          <div className="Main-Content">
            <Header
              user={user}
            />
            <div className="Main-Sections FadeInLongestShort">
              {renderRoutes()}
              {renderFooterRules()}
            </div>
          </div>
        </>
      ) : (
        <>
          <HeaderMobile
            user={user}
            setShow = {setShow}
            show = {show}
            currency={currency}
            week={week}
          />
          <Drawer
            anchor='left'
            open={show}
            onClose = {() => setShow(false)}
            variant='temporary'
            className="MainMobile-Box"
          >
            <Box
              onClick={() => setShow(false)}
            >
              <div className="MainMobile-Sidebar">
                {renderTabs()}
              </div>
              <Footer />
            </Box>
          </Drawer>
          <div className="Main-Sections FadeInLongestShort">
            <div className="Main-Sections-Systems">
              {renderRoutes()}
            </div>
            {renderFooterRules()}
          </div>
        </>
      )}
    </main>
  )
}

export default withRouter(Main)

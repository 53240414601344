import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

import ScrollAnimation from '../_common/ScrollAnimation'
import System from './System'
import {THEME} from '../../styles/muiTheme'
import './SystemsSection.scss'

const styles = {
  icon: {
    color: THEME.PRIMARY,
    marginRight: 8,
  },
  circularProgress: {
    marginLeft: 16,
    width: 30,
    height: 30,
  },
}

export default class SystemsSection extends Component {
  state = {
    isOpen: false,
    system: null,
  }

  static propTypes = {
    isPersonal: PropTypes.bool,
    systems: PropTypes.array.isRequired,
    isSystemsFetch: PropTypes.bool,
    isRegistration: PropTypes.bool,

    onRegistration: PropTypes.func,
  }

  handleRegistration = () => this.props.onRegistration(this.state.system.id, this.handleDialogClose)

  handleDialogOpen = (system) => () => this.setState({system, isOpen: true})

  handleDialogClose = () => this.setState({isOpen: false})

  render() {
    const {isPersonal, systems, isSystemsFetch, isRegistration} = this.props
    const {isOpen, system} = this.state

    const systemName = system && system.name

    return (
      <>
        <section className="SystemsSection">
          <h2 className="SystemsSection-Title">
            <span className="SystemsSection-TitleText FadeInMedium">
              {isPersonal ? 'Персонализированные' : 'Общедоступные'}
              {isSystemsFetch && <CircularProgress color="primary" style={styles.circularProgress} />}
            </span>
          </h2>
          <Grid container component="ul" spacing={3} className='SystemsSection-Block'>
            {systems.map((system, index) => (
              <Grid key={system.id} item component="li" >
                <ScrollAnimation effect="fadeInBottomSmall" duration={THEME.LONG} >
                  <System system={system} onDialogOpen={this.handleDialogOpen} />
                </ScrollAnimation>
              </Grid>
            ))}
          </Grid>
        </section>
        <Dialog open={isOpen} onClose={this.handleDialogClose}>
          <DialogTitle>{systemName}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Для доступа к приложению "{systemName}" необходимо назначение роли администратором.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleDialogClose}>
              Закрыть
            </Button>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={this.handleRegistration}
              disabled={isRegistration}
            >
              Отправить заявку
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

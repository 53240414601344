const LINKS = {
  'vaillant' : {
    'name': 'Ресурсы Vaillant',
    'links': [
      {
        name: 'Vaillant',
        description: 'Продуктовый сайт Vaillant',
        url: 'https://vaillant.com.ru/',
        iconName: 'Vaillant',
      },
      {
        name: 'Вконтакте',
        description:  'VK Vaillant',
        url:  'https://vk.com/vg.russia.vaillant',
        iconName: 'VK',
      },
      {
        name: 'VK Видео',
        description:  'VK  Видео Vaillant',
        url:  'https://vk.com/video/@vg.russia.vaillant',
        iconName:  'VKVideo',
      },
    ],
  },
  'protherm' : {
    'name': 'Ресурсы Protherm',
    'links': [
      {
        name: 'Protherm',
        description: 'Продуктовый сайт Protherm',
        url: 'https://protherm.com.ru/',
        iconName: 'Protherm',
      },
      {
        name: 'Вконтакте',
        description: 'VK Protherm',
        url: 'https://vk.com/vg.russia.protherm',
        iconName: 'VKProtherm',
      },
      {
        name: 'VK Видео',
        description: 'VK  Видео Protherm',
        url: 'https://vk.com/video/@vg.russia.protherm',
        iconName: 'VKVideoProtherm',
      },
    ],
  },
  'additional' : {
    'name': 'Дополнительные каналы и ресурсы',
    'links': [
      {
        name: 'Telegram',
        description: 'Telegram-канал Pro.Expert NEWs',
        url: 'https://t.me/proExpert_news',
        iconName: 'Telegram',
      },
    ],
  },
}

export default LINKS

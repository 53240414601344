import React, { useState } from "react";
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Rate from './Rate'
import {kc} from '../../index'
import './style.scss'
import { Drawer } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { getFormattedTelephone } from "../../utils/helpers";

HeaderMobile.propTypes = {
  user: PropTypes.object.isRequired,
  setShow: PropTypes.func,
  show: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  week: PropTypes.string.isRequired,
}

function HeaderMobile({user, setShow, show, currency, week}) {
  const userLinks = [
    {
      id: 1,
      text: 'Профиль',
      href: `${kc.authServerUrl}/realms/${kc.realm}/account`,
    },
    {
      id: 2,
      text: 'Выйти из аккаунта',
      handler() {
        kc.logout(process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : `${window.location.origin}`)
      },
    },
  ]

  const [showUser, setShowUser] = useState(false);

  const handlesetShow = () => {
    setShow(!show)
    setShowUser(false)
  }

  const handlesetShowUser = () =>  {
    setShowUser(!showUser)
    setShow(false)
  }

  const renderLinks = () => {
    return userLinks.map(link =>
      link.href ? (
        <Link key={link.id} component="a" href={link.href} target="_blank" rel="noopener noreferrer">
          {link.text}
        </Link>
      ) : (
        <Link key={link.id} onClick={link.handler}>
          {link.text}
        </Link>
      )
    )
  }

  return (
    <AppBar className="HeaderMobile" color="default" position="static" elevation={0}>
      <div className="HeaderMobile-Container Container">
        <div className="HeaderMobile-Content">
          <button className="HeaderMobile-Menu" onClick={handlesetShow} />
          <span className='Header-VGRussia' />
          <button className="Header-UserAvatar" onClick={handlesetShowUser} />
          <Drawer
            anchor='right'
            open={showUser}
            onClose = {() => setShowUser(false)}
            variant='temporary'
          >
            <li className="HeaderMobile-UserWrapper">
              <span className="Header-UserAvatar" />
              <div className="Header-UserInfo">
                <p>
                  {user.firstName} {user.middleName} {user.lastName}
                </p>
                <p className="Header-UserEmail">{getFormattedTelephone(user.login)}</p>
              </div>
            </li>
            <div className="HeaderMobile-UserLinkWrapper">
              {renderLinks()}
            </div>
          </Drawer>
        </div>
      </div>
      <Rate
        currency={currency}
        week={week}
      />
    </AppBar>
  )
}

export default React.memo(HeaderMobile)
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import Tooltip from '@material-ui/core/Tooltip'
import InsertLinkOutlined from '@material-ui/icons/InsertLinkOutlined'
import { THEME } from '../../styles/muiTheme'
import './Links.scss';


const StyledPaper = withStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
})(Paper)

const StyledButtonBase = withStyles({
  root: {
    width: '100%',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: THEME.LIGHT_BLOCK,
    transition: `background-color ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
    borderRadius: '25px !important',
    boxShadow: '0px 10px 25px -10px rgba(0, 0, 0, 0.12) !important',
  },
})(ButtonBase)

const StyledInfoIcon = withStyles({
  root: {
    padding: 6,
    width: 29,
    height: 29,
    color: '#b4bac5',
    rotate: '135deg',
    backgroundColor: '#F9FAFB',
    borderRadius: '50%'
  },
})(InsertLinkOutlined)

LinkItem.propTypes = {
  link: PropTypes.object.isRequired,
}

function LinkItem({ link }) {
  const img = `Link-Img-${link.iconName}`

  return (
    <StyledPaper className="Link">
      <StyledButtonBase focusRipple component="a" href={link.url} target={'_blank'}>
        <div className="Link-ImgBlock">
          <span className={img} />
        </div>
        <div className="Link-Description">
          {link.description}
        </div>
      </StyledButtonBase>
      <Tooltip
        disableFocusListener
        title={
          <p>
            {link.description}
            {!link.url && (
              <>
                <br />
                <span className="LightSuccess">Ожидается подключение</span>
              </>
            )}
          </p>
        }
      >
        <div className="Link-ImgBlock-Wrapper">
          <StyledInfoIcon />
        </div>
      </Tooltip>
    </StyledPaper>
  )
}

export default React.memo(LinkItem)

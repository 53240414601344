import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import ButtonBase from '@material-ui/core/ButtonBase'
import Tooltip from '@material-ui/core/Tooltip'
import InsertLinkOutlined from '@material-ui/icons/InsertLinkOutlined'

import {BASE_URL} from '../../index'
import {THEME} from '../../styles/muiTheme'
import './System.scss'

export const serviceClubRoleKey = 'service-club'
export const proExpertRoleKey = 'pro-expert-loyalty'

const StyledPaper = withStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
})(Paper)

const StyledButtonBase = withStyles({
  root: {
    width: '100%',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: THEME.LIGHT_BLOCK,
    transition: `background-color ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
    '&:hover': {
      backgroundColor: THEME.LIGHT_BLOCK_DARK,
    },
    borderRadius: '25px !important',
    boxShadow: '0px 10px 25px -10px rgba(0, 0, 0, 0.12) !important',
  },
})(ButtonBase)

const StyledInfoIcon = withStyles({
  root: {
    padding: 6,
    width: 29,
    height: 29,
    color: '#b4bac5',
    rotate: '135deg',
    backgroundColor: '#F9FAFB',
    borderRadius: '50%'
  },
})(InsertLinkOutlined)

System.propTypes = {
  system: PropTypes.object.isRequired,
  isFetch: PropTypes.bool,

  onDialogOpen: PropTypes.func.isRequired,
  onSpecialSystemClick: PropTypes.func,
}

function System({isFetch, system, onDialogOpen}) {
  const img = `${BASE_URL}/img/${system.icon}`

  return (
    <StyledPaper className="System">
      {system.active ? (
        <StyledButtonBase focusRipple component="a" href={system.url} target={'_blank'} disabled={!system.url}>
          <div className="System-ImgBlock">
            <img className="System-Img" src={img} alt={system.name} />
          </div>
          <div className="System-Description">
            {system.description}
          </div>
        </StyledButtonBase>
      ) : (
        <div className="System-Disabled">
          <div className="System-ImgBlock System-ImgBlock_disabled">
            <img className="System-Img" src={img} alt={system.name} />
          </div>
          <div className="System-Description">
            {system.description}
          </div>
        </div>
      )}
      <Tooltip
        disableFocusListener
        title={
          <p>
            {system.description}
            {!system.url && (
              <>
                <br />
                <span className="LightSuccess">Ожидается подключение</span>
              </>
            )}
          </p>
        }
      >
        <div className="System-ImgBlock-Wrapper">
          <StyledInfoIcon />
        </div>
      </Tooltip>
    </StyledPaper>
  )
}

export default React.memo(System)

import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

export const getDateFromRequest = requestDate => new Date(
  Date.UTC(
    requestDate.year,
    requestDate.monthValue - 1,
    requestDate.dayOfMonth,
    requestDate.hour,
    requestDate.minute,
    requestDate.second
  )
)


export const getFormattedTelephone = telephone => {
  if ( isValidPhoneNumber(telephone, 'RU') ) {
    const phone = parsePhoneNumber(telephone, 'RU');
    return phone.formatInternational();
  }

  if ( isValidPhoneNumber(telephone, 'BY')) {
    const phone = parsePhoneNumber(telephone, 'BY');
    return phone.formatInternational();
  }

  return telephone;
}
